import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import Container from "../components/container"
import Title from "../components/title"
import ContactForm from "../components/contact-form"
import { Envelope, Fax, MapMarker, Phone } from "../components/icons"
import styles from "./Iletisim.module.scss"

export default function Iletisim() {
  return (
    <>
      <SEO title="İletişim" />
      <Layout>
        <PageHeader title="İletişim" />
        <Container>
          <section className={styles.iletisim}>
            <div>
              <Title headingLevel="h3">MESAJ GÖNDER:</Title>
              <ContactForm />
            </div>
            <div>
              <Title headingLevel="h3">İLETİŞİM BİLGİLERİ:</Title>
              <ul className={styles.contactInfo}>
                <li>
                  <MapMarker />
                  <p>
                    <strong>Adres:</strong>
                    <span>
                      Cumhuriyet Mah. Uludağ Sk. No:15 D: 3 Beykent
                      Büyükçekmece/İstanbul
                    </span>
                  </p>
                </li>
                <li className={styles.row}>
                  <div className={styles.col}>
                    <Phone />
                    <p>
                      <strong>Telefon:</strong>
                      <span>
                        0212 871 1060
                        <br />
                        0850 495 5606
                      </span>
                    </p>
                  </div>
                  <div className={styles.col}>
                    <Fax />
                    <p>
                      <strong>Faks:</strong>
                      <span>0212 870 1061</span>
                    </p>
                  </div>
                </li>
                <li>
                  <Envelope />
                  <p>
                    <strong>E-Posta:</strong>
                    <a href="mailto:info@affakalite.com.tr">
                      info@affakalite.com.tr
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </section>

          <section className={styles.map}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12041.320767833886!2d28.6232925!3d41.0180315!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6dcf1beba4b22159!2sAffa%20Kalite!5e0!3m2!1sen!2suk!4v1605716192042!5m2!1sen!2suk"
              width="100%"
              height="450"
              frameborder="0"
              className={styles.map}
              title="harita"
            ></iframe>
          </section>
        </Container>
      </Layout>
    </>
  )
}
