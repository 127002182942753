import React from "react"
import Button from "../button"
import { Input, Textarea } from "../form-elements"
import styles from "./contact-form.module.scss"

export default function ContactForm() {
  const [submitText, setSubmitText] = React.useState("Gönder")

  return (
    <form
      className={styles.form}
      name="Iletisim Formu"
      netlify-honeypot="bot-field"
      method="POST"
      action="/tesekkurler"
      data-netlify="true"
      onSubmit={e => setSubmitText("Gönderiliyor...")}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="Iletisim Formu" />
      <div className={styles.fields}>
        <div className={styles.col}>
          <Input
            type="text"
            name="adsoyad"
            placeholder="Adınız Soyadınız"
            className={styles.input}
            required
          />
          <Input
            type="email"
            name="eposta"
            placeholder="E-Posta Adresiniz"
            className={styles.input}
            required
          />
        </div>
        <Textarea label="Mesajınız" cols="40" rows="9" name="mesaj" />
      </div>
      <div>
        <Button type="submit">{submitText}</Button>
      </div>
    </form>
  )
}
